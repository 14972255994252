module.exports = class ImageConverter {
  async webp(file, settings) {
    const img = await this.loadImage(file);
    const maxWidth = settings?.maxWidth || img.width;
    const maxHeight = settings?.maxHeight || img.height;
    const desiredAspectRatio = settings?.aspectRatio || img.width / img.height;
    const allowTransparency =
      settings?.allowTransparency !== undefined
        ? settings.allowTransparency
        : true;
    const backgroundColor = settings?.backgroundColor || "white";
    const widthRatio = maxWidth / img.width;
    const heightRatio = maxHeight / img.height;
    const ratio = Math.min(widthRatio, heightRatio, 1);
    const scaledWidth = img.width * ratio;
    const scaledHeight = img.height * ratio;
    let cropWidth = scaledWidth;
    let cropHeight = scaledHeight;
    const currentAspectRatio = scaledWidth / scaledHeight;
    if (currentAspectRatio > desiredAspectRatio)
      cropWidth = scaledHeight * desiredAspectRatio;
    else if (currentAspectRatio < desiredAspectRatio)
      cropHeight = scaledWidth / desiredAspectRatio;
    const offsetX = (scaledWidth - cropWidth) / 2;
    const offsetY = (scaledHeight - cropHeight) / 2;
    const canvas = document.createElement("canvas");
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext("2d");
    if (!allowTransparency) {
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
    ctx.drawImage(
      img,
      offsetX / ratio,
      offsetY / ratio,
      cropWidth / ratio,
      cropHeight / ratio,
      0,
      0,
      cropWidth,
      cropHeight
    );
    const blob = await this.canvasToBlob(canvas, "image/webp", 0.8);
    const webpFile = new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
      type: "image/webp",
    });
    return webpFile;
  }

  loadImage(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        URL.revokeObjectURL(img.src);
        resolve(img);
      };
      img.onerror = function (error) {
        reject(error);
      };
      img.src = URL.createObjectURL(file);
    });
  }

  canvasToBlob(canvas, type, quality) {
    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        type,
        quality
      );
    });
  }
};
