import http from "@/http-common";

const getAll = async (orgId, data) => {
  return await http
    .get(`/admin/organization/${orgId}/membership`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const create = async (orgId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/membership`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const update = async (orgId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/membership/${data.id}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const createPlan = async (orgId, membershipId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/membership/${membershipId}/plan`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const updatePlan = async (orgId, membershipId, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/membership/${membershipId}/plan/${data.id}`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const cover = async (orgId, membershipId, file) => {
  const formData = new FormData();

  formData.append("file", file);
  return await http
    .post(
      `/admin/organization/${orgId}/membership/${membershipId}/cover`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const coverRemove = async (orgId, membershipId) => {
  return await http
    .delete(`/admin/organization/${orgId}/membership/${membershipId}/cover`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAllFiliation = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/membership/filiation`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const createFiliation = async (orgId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/membership/filiation`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

// Partner
const getAllPartner = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/membership/partner`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const createPartner = async (orgId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/membership/partner`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const updatePartner = async (orgId, { id, ...data }) => {
  return await http
    .put(`/admin/organization/${orgId}/membership/partner/${id}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const uploadPhotoPartner = async (orgId, partnerId, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await http
    .put(
      `/admin/organization/${orgId}/membership/partner/${partnerId}/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const deletePartner = async (orgId, partnerId) => {
  return await http
    .delete(`/admin/organization/${orgId}/membership/partner/${partnerId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getAll,
  create,
  update,
  cover,
  coverRemove,

  createPlan,
  updatePlan,
  filiation: {
    create: createFiliation,
    getAll: getAllFiliation,
  },
  partner: {
    getAll: getAllPartner,
    create: createPartner,
    update: updatePartner,
    uploadPhoto: uploadPhotoPartner,
    delete: deletePartner,
  },
};
